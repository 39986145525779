.Promo {
	display: flex;
	border-top: 1px solid #e2;
	padding: 22px 25px;

	&Widget {
		// @startCleanup encore
		background-color: rgba(255, 255, 255, 0.75);
		border-radius: 2px;
		margin-top: 24px;
		padding: 30px 24px 26px;
		// @endCleanup encore
		box-sizing: border-box;
		display: flex;

		+encore() {
			background-color: var(--white);
			border-radius: 16px;
			padding: spacing(3);
			margin-top: spacing(4);
		}
	}

	&__img {
		width: 100px;
		// @startCleanup encore
		padding-right: 24px;
		// @endCleanup encore

		+encore() {
			padding-right: spacing(2.5);
		}
	}

	&__content {
		&&--deprecated {
			text-align: center;
		}
	}

	&__header {
		display: block;
		fab-font-weight: semibold;
		// @startCleanup encore
		margin: 0 0 6px;
		fab-color: gray10;
		fab-text: h4;
		// @endCleanup encore

		+encore() {
			fab-text: h5;
			color: var(--gray8);
			margin-bottom: spacing(0.5);
		}

		&--deprecated {
			+encore() {
				fab-text: h4;
			}
		}
	}

	&__description {
		display: block;
		// @startCleanup encore
		fab-color: gray7;
		// @endCleanup encore
		fab-text: small;
		padding-bottom: 8px;

		+encore() {
			color: var(--gray9);
		}

		&--deprecated {
			// @startCleanup encore
			padding-bottom: 11px;
			// @endCleanup encore

			+encore() {
				padding-bottom: spacing(1.5);
			}
		}
	}

	&__dateTime {
		display: block;
		// @startCleanup encore
		fab-color: gray7;
		fab-font-size: teenie;
		fab-font-weight: semibold;
		line-height: 16px;
		// @endCleanup encore

		+encore() {
			color: var(--gray9);
			fab-text: small;
			fab-font-weight: medium;
		}
	}

	&__cta {
		&Wrapper {
			text-align: center;
			display: flex;
			justify-content: space-evenly;
			max-width: 70%;
			// @startCleanup encore
			margin: 22px auto 5px;
			// @endCleanup encore

			+encore() {
				margin: spacing(3) auto spacing(3);
			}
		}
		
		&SupportedLabel {
			+encore() {
				text-align: center;
				margin: spacing(0.5) 0 spacing(1);
			}
		}

		&Item {
			display: flex;
			flex: 1;
			flex-direction: column;
			align-items: center;
		}

		&Img {
			size: 36px;
			// @startCleanup encore
			margin-bottom: 6px;
			// @endCleanup encore
			
			+encore() {
				margin-bottom: spacing(1);
			}
		}

		&Label {
			font-size: 14px;

			+encore() {
				color: var(--link);
			}
		}

		&Link {
			display: block;
			fab-text: small;
			padding-top: 8px;
			text-decoration: none;
			//@startCleanup encore
			fab-font-weight: semibold;
			fab-color: info;
			// @endCleanup encore

			+encore() {
				color: var(--link);
				fab-font-weight: medium;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		&ModalLink {
			+encore() {
				color: var(--link);
			}
		}
	}

	a {
		fab-color: info;
	}
}
