html {
	height: 100%;
}

body {
	background-image: linear-gradient(-180deg, #EEEEEE 0%, #FBFBFB 100%);
	background-repeat: no-repeat;
	background-size: cover;
	font-size: 12px;
	text-align: left;
	margin: 0;
	padding: 0;
	color: #444;
	line-height: 18px;
	min-height: 100%;
}

strong {
	font-weight: 600;
}

#sessionMessage .messageCloseWrapper {
	padding: 2px 2px 1px;
}


p {
	font-size: 18px;
	color: #777;
	line-height: 18px;
	margin: -4px auto 20px;
	line-height: 21px;
}

a {
	color: #129ced;
}
a:hover {
	text-decoration: underline;
}
a:hover {
	text-decoration: underline;
}
a > img {
	border: none;
}

h1 {
	font-weight: normal;
}

.change-password-page {
	margin-top: -25px;
}

.ChangePasswordRequirements {
	margin-top: 30px;
}

.ChangePasswordRequirements__list {
	list-style: none;
	padding: 0;
	margin: 6px 0 0 -1px;
}

.ChangePasswordRequirements__listItem {
	padding: 1px 0 1px 0;
	margin: 0;
	font-size: 15px;
}
.ChangePasswordRequirements__listItem:before {
	background-image: url("https://staticfe.bamboohr.com/resources/images/login/empty-check.svg"), url("https://staticfe.bamboohr.com/resources/images/login/filled-check.svg");
	background-position: left bottom;
	background-repeat: no-repeat;
	content: "";
	display: inline-block;
	height: 14px;
	width: 16px;
	vertical-align: middle;
	margin-top: -4px;
}

.ChangePasswordRequirements__listItem--ok:before {
	background-image: url("https://staticfe.bamboohr.com/resources/images/login/filled-check.svg");
}

.login-form > .ChangePassword__form {
	padding-top: 20px;
}

.ChangePasswordRequirements__label {
	/* @startCleanup encore */
	color: #444;
	margin-bottom: 6px;
	/* @endCleanup encore */
	font-size: 14px;
	font-weight: 600;

	+encore() {
		color: var(--gray9);
		margin-bottom: spacing(1.5);
		margin-left: 0;
	}
}

.ChangePassword__form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ChangePassword__list ul#requirements_list {
	margin: 0;
	padding: 0;
	border-left: none;
}

.ChangePassword__expired {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.ChangePassword__expiredText {
	font-size: 15px;
	color: #222;
	text-align: center;
	margin: 0 0 20px;
	line-height: 22px;
}

.ChangePassword__expiredTextHeading {
	color: #777;
	font-size: 18px;
	font-weight: 600;
	line-height: 1;
	margin-top: 30px;
	margin-bottom: 12px;
}

.ChangePassword__requestNewReset, .ChangePassword__requestNewReset:hover {
	margin-bottom: 36px;
}

/* LOGIN */
.login-form {
	padding: 0 80px;
	position: relative;
}

.login-form:after {
	border-color: rgba(225, 225, 225, 0);
	border-bottom-color: #e1e1e1;
	border-width: 8px;
	margin-left: -8px;
}

.login-form form fieldset:last-of-type {
	margin-bottom: 0;
}

.login-form:before {
	border-color: rgba(130, 130, 130, 0);
	border-bottom-color: #828282;
	border-width: 12px;
	margin-left: -12px;
}

.login-form.forgot-form:before {
  border-bottom-color: #bebebe;
}

.login-form > span {
	color: #666;
}

.login-form form {
	padding-top: 30px;
	padding-bottom: 37px;
}

.login-form form.sso-login {
	padding-bottom: 27px;
}

.login-form form.sso-login.show-normal-login {
	padding-bottom: 34px;
}

.login-form input.text {
	box-sizing: border-box;
	font-size: 20px;
	height: 36px;
	line-height: 26px;
	margin-bottom: 12px;
	margin-top: 0;
	-webkit-font-smoothing: antialiased;
}

.login-form .login-actions {
	margin-top: 9px;
}

.login-form .login-action-button,
.login-form .login-google-button {
	font-size: 20px;
	height: 36px;
	padding-bottom: 2px;
}

.login-form a.login-action-button {
	padding-bottom: 0;
	line-height: 32px;
}

.login-form .max-length-error-msg {
	font-size: 13px;
	line-height: 18px;
	color: #C20B0B;
}

.login-form .max-length-error-msg:before {
	display: none;
}

.login-form .error--maxLength {
	flex-wrap: wrap;
}

.login-form .error--maxLength input[type="password"] {
	border-color: #C20B0B;
}

.login-form .error--maxLength input[type="password"]:focus {
	box-shadow: 0 0 0 2px rgba(194, 11, 11, 0.15);
}

.login-form .error--maxLength .fab-TextInputNub .fab-TextInputNub__icon {
	fill: #C20B0B;
}

.login-google-button {
	display: block;
	position: relative;
	height: 36px;
	width: 100%;
}

.login-google-button:hover {
	height: 36px;
}

.login-google-button > span {
	background-image: url("https://staticfe.bamboohr.com/resources/images/login/google-login.png");
	background-position: center center;
	background-repeat: no-repeat;
	display: inline-block;
	height: 24px;
	margin: 2px 5px 0 0;
	vertical-align: top;
	width: 24px;
}

.login-page-execupay #powered {
	bottom: -38px;
}
.login-page-execupay .powered-img {
	margin-left: 3px;
}

input + span.domain {
	left: 185px;
}

.forgot-form {
	padding: 24px 80px 37px;
	background: #ffffff;
	border-color: #bebebe;
}

.forgot-form:after {
	border-bottom-color:#ffffff;
}

.forgot-form > p {
	margin-bottom: 5px;
}

.forgot-form .login-actions {
	margin-top: 7px;
}

ul.message {
	list-style: none;
	margin: 0 auto 30px;
	width: 260px;
	padding: 0;
	font-size: 11px;

}

ul.message  {
	color: #000;
	font-family: Helvetica, Arial, sans-serif;
	line-height: 20px;
	padding-left: 0;
}

ul.message .errorIcon  {
	display: inline-block;
	width: 23px;
	height: 23px;
	float: left;
}

ul.message .sessionMsg.error{
	color: #e43939;
}

ul.message .sessionMsg.error .errorIcon {
	background: url("https://staticfe.bamboohr.com/resources/images/login/sprite.gif") no-repeat 0 -53px;
}

ul.message .sessionMsg.success{
	color: #8bba00;
}

ul.message .sessionMsg.success .errorIcon {
	background: url("https://staticfe.bamboohr.com/resources/images/login/sprite.gif") no-repeat 0 -78px;
}

ul.message .sessionMsg.info .errorIcon {
	/* no sprite for info so don"t leave space */
	width: 0;
	height: 0;
}

.companyLogo {
	padding-top: 8px;
	text-align: center;
	margin-bottom: 12px;
}

.companyLogo img {
	max-width: 315px;
}

fieldset {
	margin: auto;
	padding: 0;
	display: block;
	border: 0;
}

a.forgot {
	font-weight: normal;
	font-size: 13px;
	line-height: 13px;
	color: #888888 !important;
	text-decoration: none;
	margin-left: 15px;
	vertical-align: middle;
}

a.forgot:hover {
	text-decoration: underline;
}

.fieldWrap {
	position: relative;
}

/* placeholder styling */
.placeholder {
	left: 1px !important;
	color: #aaa !important;
}

.icon {
	display: block;
	fill: #828282;
	left: 9px;
	position: absolute;
	top: 9px;
}

.ieOld .icon {
	top: 9px;
}

.icon.iconEmail {
	top: 10px;
}


.icon.iconPassword,
.icon.iconPasswordConfirm {
	left: 11px;
}

.icon.iconDomain {
	top: 8px;
}

.icon.iconCheckCircle {
	height: 20px;
	left: initial;
	right: 9px;
	top: 8px;
}

.or-divider {
	position: relative;
/* @startCleanup encore*/
border-bottom: solid #D7D7D7 1px;
 /*@endCleanup encore*/
margin: 31px 0 27px;
	+encore(){
		border-bottom: 1px solid var(--gray2);
	}
}

.or-divider .or-text {
	background: #FFFFFF;
	/* @startCleanup encore*/
	color: #888888;
	/*@endCleanup encore*/
	display: block;
	font-size: 13px;
	padding: 0 9px;
	position: absolute;
	left: 50%;
	text-transform: uppercase;
	top: 50%;
	transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	+encore(){
			color: var(--gray6);
			font-weight: var(--font-weight-medium);
	}
}

.normal-login-link-container {
	text-align: center;
}

.normal-login-link-container > a {
	color: #006EC2;
	cursor: pointer;
	font-size: 15px;
	text-decoration: none;
}

.normal-login-link-container > a:hover {
	text-decoration: underline;
}

.sso-login.show-normal-login .normal-login-link-container {
	display: none;
}

.sso-login.show-normal-login #passwordFields {
	display: block;
	max-height: 400px;
	opacity: 1;
	+encore() {
		overflow: visible;
	}
}

.sso-login #passwordFields {
	max-height: 0;
	transition: max-height 1s cubic-bezier(.31, .80, 1, 1), opacity 0.5s;
	opacity: 0;
	overflow: hidden;
}

/*** CSS flipper styles ***/
/* entire container, keeps perspective */
body .flip-container {
	height: 400px;
	width: 608px;
}

body .flip-container .flipper {
	position: relative;
	height: 400px;
	width: 608px;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* hide back of pane during swap */
body .flip-container .flipper .front,
body .flip-container .flipper .back {
	position: absolute;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: perspective(1000px) rotateY(180deg);
	-moz-transform: perspective(1000px) rotateY(180deg);
	-o-transform: perspective(1000px) rotateY(180deg);
	transform: perspective(1000px) rotateY(180deg);
	-webkit-transition: 0.6s;
	-moz-transition: 0.6s;
	-o-transition: 0.6s;
	transition: 0.6s;
}

/* front pane, placed above back */
body .flip-container .flipper .front {
	z-index: 2;
	-webkit-transform: perspective(1000px) rotateY(0);
	-moz-transform: perspective(1000px) rotateY(0);
	-o-transform: perspective(1000px) rotateY(0);
	transform: perspective(1000px) rotateY(0);
}

/* front pane, placed above back */
body .flip-container.flip .flipper .front {
	-webkit-transform: perspective(1000px) rotateY(-179.9deg);
	-moz-transform: perspective(1000px) rotateY(-179.9deg);
	-o-transform: perspective(1000px) rotateY(-179.9deg);
	transform: perspective(1000px) rotateY(-179.9deg);
}

/* back, initially hidden pane */
body .flip-container.flip .flipper .back {
	-webkit-transform: perspective(1000px) rotateY(0);
	-moz-transform: perspective(1000px) rotateY(0);
	-o-transform: perspective(1000px) rotateY(0);
	transform: perspective(1000px) rotateY(0);
}

body .flip-container .flipper .front.forgot {
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: perspective(1000px) rotateY(180deg);
	-moz-transform: perspective(1000px) rotateY(180deg);
	-o-transform: perspective(1000px) rotateY(180deg);
	transform: perspective(1000px) rotateY(180deg);
	-webkit-transition: 0s;
	-moz-transition: 0s;
	-o-transition: 0s;
	transition: 0s;
}

body .flip-container .flipper .back.forgot {
	-webkit-backface-visibility: visible;
	-moz-backface-visibility: visible;
	backface-visibility: visible;
	-webkit-transform: perspective(1000px) rotateY(0);
	-moz-transform: perspective(1000px) rotateY(0);
	-o-transform: perspective(1000px) rotateY(0);
	transform: perspective(1000px) rotateY(0);
	-webkit-transition: 0s;
	-moz-transition: 0s;
	-o-transition: 0s;
	transition: 0s;
}

.PageCard,
.login-pane {
	background: #FFFFFF;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	border-radius: 4px;
	border-bottom: 4px solid;
}

.login-pane {
	position: relative;
	margin-bottom: 100px;
}

.ieOld .flip-container .flipper .back {
	display: none;
}

/** zen login specific **/
.zendesk-login #login-page {
	width: 420px;
	transform: initial;
	margin-left: -210px;
	top: 25%;
}

.zenlogin #domain {
	width: 190px;
}

.zenlogin .domain {
	position: relative;
	top: -2px;
	left: 0;
	margin-left: 6px;
	font-size: 20px;
	color: #acacac;
}

.footer__oldBrowser {
	min-height: 250px;
}

.footer__oldBrowser .close {
	position: absolute;
	display: block;
	top: -12px;
	right: 25px;
	width: 23px;
	height: 23px;
	line-height: 0;
	color: transparent;
	font-size: 0;
	text-align: center;
	background: url("https://staticfe.bamboohr.com/resources/images/ie8-close-circle.png") no-repeat top center;
	z-index: 100;
}

.footer__oldBrowser .close:hover {
	background-position: bottom center;
}

.footer__oldBrowser h3 {
	font-weight: normal;
	font-size: 1.6em;
	margin-bottom: 16px;
}

.footer__oldBrowser p {
	font-size: 1.2em;
}

.footer__oldBrowser .container {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9000;
	background: #eee;
	padding: 38px 26px 15px;
	border-top: 1px solid #cdcdcd;
}

.footer__oldBrowser .snail,
.footer__oldBrowser .snail-trail {
	position: absolute;
	display: block;
}

.footer__oldBrowser .snail-trail {
	top: -6px;
	left: 0;
	min-width: 100px;
	max-width: 90%;
	height: 11px;
	background-image: url("https://staticfe.bamboohr.com/resources/images/snail-trail.png");
}

.footer__oldBrowser .snail {
	right: -40px;
	bottom: 2px;
	background-image: url("https://staticfe.bamboohr.com/resources/images/snail.png");
	width: 58px;
	height: 35px;
	margin-right: 15px;
}

.footer__oldBrowser .snail:before {
	content: " ";
	position: absolute;
	display: block;
	bottom: 0;
	left: 55px;
	height: 3px;
	border-top: 1px solid #eee;
	width: 15px;
}

.footer__oldBrowser ul.browsers {
	float: right;
	padding-left: 45px;
}

.footer__oldBrowser ul.browsers li {
	float: left;
	padding-left: 10px;
}

.footer__oldBrowser ul.browsers a {

}

.footer__oldBrowser ul.browsers span {
	display: block;
}

.footer__oldBrowser ul.browsers span.chrome-logo,
.footer__oldBrowser ul.browsers span.firefox-logo,
.footer__oldBrowser ul.browsers span.ie-logo {
	border: 1px solid #cdcdcd;
	padding: 12px 28px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
}

.footer__oldBrowser ul.browsers span.browser-name {
	visibility: hidden;
	text-align: center;
	color: #777;
	padding-top: 12px;
}

.footer__oldBrowser ul.browsers a:hover span.browser-name {
	visibility: visible;
}

.footer__oldBrowser ul.browsers a:hover span.chrome-logo,
.footer__oldBrowser ul.browsers a:hover span.firefox-logo,
.footer__oldBrowser ul.browsers a:hover span.ie-logo {
	background: #f9f9f9;
}

.footer__oldBrowser ul.browsers span.chrome-logo:before,
.footer__oldBrowser ul.browsers span.firefox-logo:before,
.footer__oldBrowser ul.browsers span.ie-logo:before {
	content: " ";
	display: block;
	background: url("https://staticfe.bamboohr.com/resources/images/browser-icons.png");
	width: 56px;
	height: 56px;
}

.footer__oldBrowser ul.browsers span.chrome-logo:before {
	background-position: left bottom;
}

.footer__oldBrowser ul.browsers span.firefox-logo:before {
	background-position: center bottom;
}

.footer__oldBrowser ul.browsers span.ie-logo:before {
	background-position: right bottom;
}

.footer__oldBrowser ul.browsers a:hover span.chrome-logo:before {
	background-position: left top;
}

.footer__oldBrowser ul.browsers a:hover span.firefox-logo:before {
	background-position: center top;
}

.footer__oldBrowser ul.browsers a:hover span.ie-logo:before {
	background-position: right top;
}


/**
 * Login notice styles
 */

.login-notice {
	position: absolute;
	top: -65px;
	z-index: 3;
	width: 420px;
	padding: 14px 0 0;
	background-color: #84bf41;
	border-radius: 4px 4px 0 0;
}
.login-notice-message {
	margin: 0 auto 13px;
	color: #fff;
	font-size: 15px;
}
.login-notice-message-lrg {
	font-size: 20px;
}

/**
 * BambooPayroll
 */

.login-page-execupay .login-notice {
	background-color: #81b100;
}
.login-page-execupay .login-pane {
	border-bottom-color: #81b100;
}
.login-page-execupay .login-form .login-action-button {
	background-color: #81b100;
	border-color: #6d9b00;
}
.login-page-execupay .icon.baseFillColor {
	fill: #81b100;
}
