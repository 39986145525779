#login-page {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	width: auto;
}

.or-divider {
	margin: 35px 0 25px;

	.or-text {
		fab-font-size: small;
		fab-color: gray6;
		fab-font-weight: bold;
	}
}

.mobileOnly,
.InterstitialFooter__mobile--tabletViewOnly {
	display: none;
}

.FabLogin {
	&__separator {
		// @startCleanup encore
		margin-bottom: 26px;
		// @endCleanup encore
		+encore() {
			margin-bottom: 32px;
			border-color: var(--gray2);
		}

		&--noTitle {
			margin-top: 0;
			margin-bottom: 15px;
		}
	}
	// .FabLogin__content
	&__content {
		display: flex;
		justify-content: center;
	}

	// .FabLogin__form
	&__form {
		padding-bottom: 22px;
		display: flex;
		flex-direction: column;
		align-items: center;

		&--tight {
			padding-bottom: 18px;
		}
	}

	// .FabLogin__logo
	&__logo {
		margin: 10px 0 40px;
		text-align: center;

		> img {
			max-width: 450px;
		}

		&--bamboohrGreen {
			fill: $logoColor.bamboohr;
		}

		// .FabLogin__logo--smallMarginBottom
		&--smallMarginBottom {
			margin-bottom: 22px;
		}

		// .FabLogin__logo--noMarginBottom
		&--noMarginBottom {
			margin-bottom: 0;
		}

		&Title {
			fab-text: large;
			fab-color: gray9;
			fab-font-weight: semibold;
			margin-top: 18px;
			margin-bottom: 0;
		}

		&--borderBottom {
			position: relative;
			margin: 0 0 5px;
		}
	}
}

.ForgotPassword {
	max-width: 352px;

	// .ForgotPassword__instruction
	&__instruction {
		fab-color: gray10;
		// @startCleanup encore
		margin-bottom: 16px;
		// @endCleanup encore
		text-align: center;

		+encore() {
			margin-bottom: 32px;
			margin-top: 40px;
			fab-font-weight: medium;
		}
	}
}
