@require "SimpleModal.mod";

.DeprecatedBrowserModal {
	.SimpleModal__body {
		width: 512px;
		padding: 20px 22px 12px;
	}

	h3 {
		font-size: 18px;
		font-weight: 500;
		margin-top: 6px;
		margin-bottom: 14px;
	}

	h5 {
		text-align: center;
		font-size: 14px;
		font-weight: 600;
		margin-top: 27px;
	}

	p {
		color: #2;
		font-size: 14px;
		line-height: 1.3em;
		margin-bottom: 20px;
	}

	.Promo__ctaWrapper.Promo__ctaWrapper--deprecated {
		margin-bottom: 33px;
	}
}
